const headings = {
  h1: {
    fontFamily: 'heading',
    fontSize: [6, null, null, 7],
  },
  h2: {
    fontFamily: 'heading',
    fontSize: [5, null, null, 6],
  },
  h3: {
    fontFamily: 'heading',
    fontSize: 4,
  },
  h4: {
    fontSize: 3,
    fontFamily: 'heading',
  },
  h5: {
    fontSize: 2,
    fontFamily: 'heading',
  },
  h6: {
    fontSize: 1,
    fontFamily: 'heading',
  },
}
const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],
  fonts: {
    body: 'National2, sans-serif',
    heading: 'National2Condensed, National2',
  },
  fontSizes: [12, 14, 16, 21, 24, 36, 48, 60],
  colors: {
    text: '#000',
    background: '#fff',
    primary: '#F40027',
    secondary: '#231F20',
  },
  text: {
    ...headings,
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: '1.5',
      fontWeight: '500',
      fontSize: 2,
    },
    ...headings,
    a: {
      color: 'text',
      textDecoration: 'none',
    },
    img: {
      verticalAlign: 'middle',
      maxWidth: '100%',
    },
    em: {
      fontFamily: 'body',
      fontStyle: 'italic',
      fontWeight: '400',
    },
    strong: {
      fontFamily: 'body',
      fontWeight: '700',
    },
  },
}

export default theme
